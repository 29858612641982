import * as React from "react";
import About from "./Home/About";
import Tokenomics from "./Home/Tokenomics";
import Roadmap from "./Home/Roadmap";
import Community from "./Home/Community";

export default function Home() {
  return (
    <>
      <About />
      <Tokenomics />
      <Roadmap />
      <Community />
    </>
  );
}
