import * as React from "react";
import { CssVarsProvider, extendTheme, getInitColorSchemeScript } from "@mui/joy/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MenuIcon from "@mui/icons-material/Menu";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Route, Routes } from "react-router-dom";
import { Box, Typography, CssBaseline, IconButton } from "@mui/joy";

import Navigation from "./components/Navigation";
import Layout from "./components/Layout";
import ColorSchemeToggle from "./components/ColorSchemeToggle";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Ladder from "./components/Ladder";
import Statistics from "./components/Statistics";

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: "var(--joy-palette-neutral-50)",
        },
      },
    },
    dark: {
      palette: {
        background: {
          body: "var(--joy-palette-common-black)",
          surface: "var(--joy-palette-neutral-900)",
        },
      },
    },
  },
  fontFamily: {
    display: "Montserrat, 'Inter', var(--joy-fontFamily-fallback)",
    body: "Montserrat, 'Inter', var(--joy-fontFamily-fallback)",
  },
});

export default function App({ supabase }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <CssVarsProvider disableTransitionOnChange theme={theme}>
      <CssBaseline />
      {getInitColorSchemeScript()}
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Navigation />
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: "100vh",
            overflow: "hidden",
          }),
        }}
      >
        <Layout.Header>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <IconButton
              variant="outlined"
              size="sm"
              onClick={() => setDrawerOpen(true)}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="/logo.png"
              alt="logo"
              width="48"
              height="48"
              style={{ borderRadius: 4 }}
            />
            <Typography
              component="h1"
              fontWeight="xl"
              // sx={{ letterSpacing: 1 }}
            >
              BIGCAP
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
            <IconButton
              size="sm"
              variant="outlined"
              color="primary"
              sx={{ display: { xs: "inline-flex", sm: "none" } }}
            >
              <SearchRoundedIcon />
            </IconButton>
            <Menu
              id="app-selector"
              control={
                <IconButton
                  size="sm"
                  variant="outlined"
                  color="primary"
                  aria-label="Apps"
                >
                  <WidgetsIcon />
                </IconButton>
              }
              menus={[
                {
                  label: "Uniswap",
                  href: "https://app.uniswap.org/#/swap?outputCurrency=0xE29F1241E6015A4949cb7E3f07234BA794006181",
                  target: "_blank",
                },
                {
                  label: "Whitepaper",
                  href: "/whitepaper.pdf",
                  target: "_blank",
                },
                {
                  label: "DAO",
                  href: "https://www.tally.xyz/gov/eip155:1:0x442660DDf67dd90f9a75885b2e2312F991b3027B",
                  target: "_blank",
                },
                {
                  label: "Telegram",
                  href: "https://t.me/bigcaptoken",
                  target: "_blank",
                },
                {
                  label: "Twitter",
                  href: "https://twitter.com/BIGCAPProject",
                  target: "_blank",
                },
              ]}
            />
            <ColorSchemeToggle />
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          <Navigation />
        </Layout.SideNav>
        <Layout.Main>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="ladder" element={<Ladder supabase={supabase} />} />
            <Route path="statistics" element={<Statistics />} />
          </Routes>
          <Footer />
        </Layout.Main>
      </Layout.Root>
    </CssVarsProvider>
  );
}
