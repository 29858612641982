import * as React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";

import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TokenIcon from "@mui/icons-material/Token";
import StarIcon from "@mui/icons-material/Star";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

export default function EmailNav() {
  return (
    <List size="sm" sx={{ "--List-item-radius": "8px" }}>
      <ListItem nested>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton component="a" href="/">
              <ListItemDecorator sx={{ color: "inherit" }}>
                <TokenIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>ABOUT</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component="a"
              href="/whitepaper.pdf"
              target="_blank"
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <TextSnippetIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>WHITEPAPER</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component="a" href="/#/ladder">
              <ListItemDecorator sx={{ color: "inherit" }}>
                <StarIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>LADDER</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component="a" href="/#/statistics">
              <ListItemDecorator sx={{ color: "inherit" }}>
                <StackedLineChartIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>STATISTICS</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component="a"
              href="https://twitter.com/BIGCAPProject"
              target="_blank"
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <TwitterIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>TWITTER</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component="a"
              href="https://t.me/bigcaptoken"
              target="_blank"
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <TelegramIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>TELEGRAM</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component="a"
              href="https://www.tally.xyz/gov/eip155:1:0x442660DDf67dd90f9a75885b2e2312F991b3027B"
              target="_blank"
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <HowToVoteIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>DAO</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    </List>
  );
}
