import * as React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Sheet,
  Typography,
  Button,
  Grid,
} from "@mui/joy";

export default function Tokenomics() {
  return (
    <Sheet
      variant="plain"
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          level="display2"
          textColor="text.primary"
          textAlign="center"
          sx={{ letterSpacing: 1 }}
        >
          <i>100M SUPPLY</i>
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          py: 2,
        }}
      >
        {[
          { text: "ETHERSCAN", href: "https://etherscan.io/token/0xE29F1241E6015A4949cb7E3f07234BA794006181" },
          { text: "UNISWAP", href: "https://app.uniswap.org/#/swap?outputCurrency=0xE29F1241E6015A4949cb7E3f07234BA794006181" },
        ].map((item) => (
          <Button
            key={item.text}
            color="success"
            variant="soft"
            size="lg"
            sx={{ m: 1 }}
            component="a"
            href={item.href}
            target="_blank"
            endDecorator={<OpenInNewIcon />}
          >
            {item.text}
          </Button>
        ))}
      </Box>
      <Grid container spacing={4} textAlign="center">
        {[
          {
            title: "Fairly Launched",
            subtitle: "No presale, team supply allocations, or minting after launch.",
            content: ""
          },
          {
            title: "Vote for Governance",
            subtitle: "1 BIGCAP = 1 Vote. Control the treasury and ladders."
          },
          {
            title: "Stake for Collateral",
            subtitle: "Stake BIGCAP to trade the treasury. Return a profit to unstake."
          },
        ].map((item) => (
          <Grid xs={12} sm={6} key={item.title}>
            <Typography level="h2">
              <i>{item.title}</i>
            </Typography>
            {item.subtitle && (
              <Typography level="h4">
                {item.subtitle}
              </Typography>
            )}
            {item.content && (
              <Typography level="h5">
              {item.content}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Sheet>
  );
}
