import * as React from "react";
import LadderContainer from "./LadderContainer";
import ConnectWallet from "./ConnectWallet";
import Trader from "./Trader";
import { useEthers } from "@usedapp/core";
import { useSupabase } from "../hooks";

export default function Ladder() {
  const { account } = useEthers();
  const supabase = useSupabase();
  const [trader, setTrader] = React.useState({});
  const [isRegistered, setRegistered] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [trades, setTrades] = React.useState([]);
  const [whitelist, setWhitelist] = React.useState([]);
  const [ladders, setLadders] = React.useState([]);

  React.useEffect(() => {
    const sub = supabase
      .channel("any")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "whitelist" },
        async (payload) => {
          if (payload.eventType === "UPDATE") {
            let newWhitelist = whitelist;
            const idx = newWhitelist.findIndex((w) => w.id === payload.old.id);
            if (idx !== -1) {
              console.log("updating whitelist entry", newWhitelist[idx]);
              newWhitelist[idx] = { ...payload.new };
              setWhitelist(newWhitelist);
            }
          }
        }
      )
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "events" },
        async (payload) => {
          console.log(payload);
          if (supabase) {
            const { data, error } = await supabase.from("events").select("*");
            !error && setEvents(data);
          }
        }
      )
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "ladders" },
        async (payload) => {
          console.log(payload);
          if (supabase) {
            const { data, error } = await supabase.from("ladders").select("*");
            !error && setLadders(data);
          }
        }
      )
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "traders" },
        async (payload) => {
          console.log(payload);
          if (supabase && account) {
            const { data, error } = await supabase
              .from("traders")
              .select("*")
              .eq("wallet_address", account);
            !error && data.length > 0 && setTrader(data[0]);
          }
        }
      )
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "trades" },
        async (payload) => {
          console.log(payload);
          if (supabase && trader) {
            const { data, error } = await supabase
              .from("trades")
              .select("*")
              .eq("trader_id", trader.id);
            !error && setTrades(data);
          }
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(sub);
    };
  }, [supabase, account, trader, whitelist]);

  React.useEffect(() => {
    const getData = async () => {
      const { data: whitelistData, error: whitelistError } = await supabase
        .from("whitelist")
        .select("*");
      !whitelistError && setWhitelist(whitelistData);

      const { data: eventsData, error: eventsError } = await supabase
        .from("events")
        .select("*");
      !eventsError && setEvents(eventsData);

      const { data: laddersData, error: laddersError } = await supabase
        .from("ladders")
        .select("*");
      !laddersError && setLadders(laddersData);

      const { data: tradersData, error: tradersError } = await supabase
        .from("traders")
        .select("*")
        .eq("wallet_address", account);
      !tradersError && tradersData.length > 0 && setTrader(tradersData[0]);

      const { data: tradesData, error: tradesError } = await supabase
        .from("trades")
        .select("*")
        .eq("trader_id", tradersData[0]?.id);
      !tradesError && setTrades(tradesData);
    };
    if (account && supabase) {
      getData();
    }
  }, [account, supabase, trader]);

  return account ? (
    <>
      <Trader account={account} trader={trader} />
      <LadderContainer
        account={account}
        trader={trader}
        isRegistered={isRegistered}
        setRegistered={setRegistered}
        events={events}
        trades={trades}
        ladders={ladders}
        whitelist={whitelist}
      />
    </>
  ) : (
    <ConnectWallet />
  );
}
