import { useContractFunction, useCall } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import { createClient } from "@supabase/supabase-js";

import STAKE_ABI from "./stakeCollateral.json";
import TOKEN_ABI from "./tokenContract.json";
import { memo, useMemo } from "react";

export const WETH_CONTRACT = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const STAKE_CONTRACT = "0x87dc49c256b87747af50f93f053A6508275A9fD4";
export const TOKEN_CONTRACT = "0xE29F1241E6015A4949cb7E3f07234BA794006181";
export const PRICE_CONTRACT = "0x07D91f5fb9Bf7798734C3f606dB065549F6893bb";
export const ETH_CONTRACT = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const getStakeContract = () => new Contract(STAKE_CONTRACT, STAKE_ABI);
export const getTokenContract = () => new Contract(TOKEN_CONTRACT, TOKEN_ABI);

export const useGetValue = (method, args, contractAddress, contract) => {
  const { value, error } =
    useCall(
      contractAddress && {
        contract,
        method,
        args,
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return {};
  }
  return value;
};

export const useGetSingleValue = (method, args, contractAddress, contract) => {
  const { value, error } =
    useCall(
      contractAddress && {
        contract,
        method,
        args,
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return {};
  }
  return value?.[0];
};

export function useTokenApprove() {
  const c = getTokenContract();
  const { state, send, events, resetState } = useContractFunction(
    c,
    "approve",
    {
      transactionName: "Approve",
    }
  );
  return {
    state,
    send,
    events,
    resetState,
  };
}

export function useDepositCollateral() {
  const c = getStakeContract();
  const { state, send, events, resetState } = useContractFunction(
    c,
    "deposit",
    {
      transactionName: "Deposit Collateral",
    }
  );
  return {
    state,
    send,
    events,
    resetState,
  };
}

function getSupabaseBrowserClient() {
  return createClient('https://dbcwiohqvtzskjsvifhc.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRiY3dpb2hxdnR6c2tqc3ZpZmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzE2ODU4NDUsImV4cCI6MTk4NzI2MTg0NX0.2blXBXKIuEpUUMA7zIzkztEX9sIhNqECFIasVc3jPbw');
}

export function useSupabase() {
  return useMemo(getSupabaseBrowserClient, []);
}

export const loadTrader = (supabase, account) => memo(async () => {
  const { data, error } = await supabase.from("traders").select("*").eq("wallet_address", account);
  return !error && data;
});

export const loadWhitelist = (supabase) => memo(async () => {
  const { data, error } = await supabase.from("whitelist").select("*");
  return !error && data;
});

export const loadLadders = (supabase) => memo(async () => {
  const { data, error } = await supabase.from("ladders").select("*");
  return !error && data;
});

export const loadEvents = (supabase) => memo(async () => {
  const { data, error } = await supabase.from("events").select("*");
  return !error && data;
});

export const loadTrades = (supabase, trader) => memo(async () => {
  const { data, error } = await supabase.from("trades").select("*").eq("trader_id", trader.id);
  return !error && data;
});