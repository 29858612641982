import * as React from "react";
import { Box, Typography, Grid } from "@mui/joy";

export default function Roadmap() {
  return (
    <Box
      variant="plain"
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
      }}
    >
      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          level="display2"
          textColor="text.primary"
          sx={{ letterSpacing: 1 }}
        >
          <i>ROADMAP</i>
        </Typography>
        <Typography level="body1" textColor="text.secondary" textAlign="center">
          BIGCAP is a community-driven trading game. Once the first ladder
          starts, the community decides what happens next, and where the roadmap
          goes next!
        </Typography>
      </Box>
      <Grid container spacing={2} textAlign="center">
        <Grid xs={12} md={4}>
          <Typography level="h3" textColor="text.primary">
            Q4 2022
          </Typography>
          <Typography level="h5" textColor="text.secondary">
            Launch & Treasury Growth
          </Typography>
          <Typography level="h5" textColor="text.secondary">
            Ladder Development
          </Typography>
        </Grid>
        {/* <Grid xs={12} md={1}>
          <Typography>
          <FontAwesomeIcon icon="fa-solid fa-circle-nodes" />
          </Typography>
        </Grid> */}
        <Grid xs={12} md={4}>
          <Typography level="h3" textColor="text.primary">
            Q1 2023
          </Typography>
          <Typography level="h5" textColor="text.secondary">
            Ladder Audit
          </Typography>
          <Typography level="h5" textColor="text.secondary">
            First Ladder Launch
          </Typography>
        </Grid>
        {/* <Grid xs={12} md={1}>
          <Avatar>
            <FontAwesomeIcon icon="fa-solid fa-circle-nodes" />
          </Avatar>
        </Grid> */}
        <Grid xs={12} md={4}>
          <Typography level="h3" textColor="text.primary">
            Q2 2023+
          </Typography>
          <Typography level="h5" textColor="text.secondary">
            Monthly Ladder Seasons
          </Typography>
        </Grid>
        {/* <Grid xs={12} md={1}>
          <FontAwesomeIcon icon="fa-solid fa-circle-nodes" />
        </Grid> */}
      </Grid>
    </Box>
  );
}
