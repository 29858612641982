import * as React from "react";
import axios from "axios";
import {
  Typography,
  Alert,
  Card,
  Divider,
  Button,
  Stack,
  Table,
  Grid,
  useTheme,
} from "@mui/joy";
import InfoIcon from "@mui/icons-material/Info";
import { shortenIfAddress, useSigner } from "@usedapp/core";

import { API_ADDRESS } from "../helpers";
import WhitelistToken from "./Ladder/WhitelistToken";

export default function LadderContainer({
  account,
  trader,
  ladders,
  events,
  trades,
  whitelist,
}) {
  const theme = useTheme();
  const [isLoading, setLoading] = React.useState(false);
  const [isLoadingRegister, setLoadingRegister] = React.useState(false);
  const [registerError, setRegisterError] = React.useState("");
  const signer = useSigner();

  const onClickRegisterForLadder = async (e, id) => {
    e.preventDefault();
    try {
      setLoadingRegister(true);
      setRegisterError("");
      const data = {
        addr: account,
        id,
      };
      const response = await axios.post(
        `${API_ADDRESS}/registerForLadder`,
        data
      );
      console.log(response);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setRegisterError(error.response.data.message);
      } else {
        setRegisterError(error.message);
      }
    } finally {
      setLoadingRegister(false);
    }
  };

  return ladders
    .filter((ladder) => ladder.id !== 0)
    .map((ladder) => (
      <Grid container justifyContent="center" rowSpacing={4} key={ladder.id}>
        <Grid xs={12} md={8} lg={6} xl={4}>
          <Card variant="outlined">
            <Stack spacing={1} sx={{ textAlign: "center" }}>
              <Typography level="h2">
                <i>LADDER #{ladder.id}</i>
              </Typography>
              <Divider />
              <Typography level="h3">
                <i>"{ladder.name}"</i>
              </Typography>
              <Table variant="soft" size="sm" color="primary">
                <tbody>
                  {ladder.paused && (
                    <tr
                      style={{
                        backgroundColor: theme.palette.danger.plainHoverBg,
                        color: theme.palette.danger.plainColor,
                      }}
                    >
                      <th width={150}>Paused</th>
                      <td>
                        Ladder is paused. Trading is unavailable while the
                        ladder is paused.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th width={150}>Ladder Type</th>
                    <td>{ladder.type}</td>
                  </tr>
                  <tr>
                    <th width={150}>Ladder Starts</th>
                    <td>{new Date(ladder.start_timestamp).toDateString()}</td>
                  </tr>
                  <tr>
                    <th width={150}>Ladder Ends</th>
                    <td>{new Date(ladder.end_timestamp).toDateString()}</td>
                  </tr>
                  <tr>
                    <th width={150}>Starting Balance</th>
                    <td>{ladder.starting_eth} ETH</td>
                  </tr>
                </tbody>
              </Table>
              {ladder.traders &&
                ladder.traders.indexOf(trader.wallet_address) === -1 && (
                  <Alert
                    color="warning"
                    sx={{ textAlign: "left" }}
                    startDecorator={React.cloneElement(<InfoIcon />, {
                      sx: { mt: "2px", mx: "2px" },
                    })}
                  >
                    <Typography level="body2">
                      You are not registered for this ladder. Register now to
                      participate in the ladder.
                    </Typography>
                    <Button
                      color="success"
                      onClick={(e) => onClickRegisterForLadder(e, ladder.id)}
                      loadingPosition="start"
                      disabled={!trader.id}
                      loading={isLoadingRegister}
                      sx={{ ml: 1 }}
                    >
                      Register For Ladder
                    </Button>
                  </Alert>
              )}
              {registerError && <Alert color="danger">{registerError}</Alert>}
              <Divider />
              <Typography level="h4" sx={{ textTransform: "uppercase" }}>
                <i>Whitelisted Tokens</i>
              </Typography>
              <Alert color="info" sx={{ textAlign: "left" }}>
                <Typography level="body2">
                  This ladder allows you to buy tokens for 0.1 ETH. You can only
                  purchase one token at a time with the aim of selling it when
                  the value for 0.1 ETH decreases. For instance, if you buy 1
                  AAVE and its price increases to 0.5 AAVE, you can sell the
                  AAVE you bought for a profit. If the price decreases to 2
                  AAVE, then you would be selling the token at a loss and your
                  collateral may be used to cover your losses.
                </Typography>
              </Alert>
              <Table variant="plain" color="info" size="lg">
                <tbody>
                  {whitelist
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((item) => (
                      <WhitelistToken
                        key={item.id}
                        item={item}
                        isLoading={isLoading}
                        setLoading={setLoading}
                        id={ladder.id}
                        signer={signer}
                        account={account}
                        trader={trader}
                        trades={trades.filter(
                          (el) => el.ladder_id === ladder.id
                        )}
                        positionOpened={
                          trader && trader.status === "BUY_VERIFIED"
                        }
                        isRegistered={
                          ladder.traders &&
                          ladder.traders.indexOf(account) !== -1
                        }
                      />
                    ))}
                </tbody>
              </Table>
              <Alert
                color="warning"
                variant="outlined"
                sx={{ textAlign: "left" }}
              >
                <Typography level="body3">
                  Displayed prices may not reflect the actual price. The price
                  may change between the time of your purchase request and the
                  time the transaction is completed.
                </Typography>
              </Alert>
              <Typography level="body3">Live prices provided by 0x</Typography>
              <Divider />
              {ladder.traders && ladder.traders.length > 0 && (
                <Typography level="h4" sx={{ textTransform: "uppercase" }}>
                  <i>Ladder Traders</i>
                </Typography>
              )}
              {ladder.traders && (
                <Table variant="plain" size="md" sx={{ textAlign: "center" }}>
                  <tbody>
                    {ladder.traders.map((trader) => (
                      <tr key={trader}>
                        <td
                          style={{
                            fontFamily: "Roboto Mono",
                            color:
                              trader === account &&
                              theme.palette.success.plainColor,
                          }}
                        >
                          {trader}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {events && events.length > 0 && (
                <Typography level="h4" sx={{ textTransform: "uppercase" }}>
                  <i>Ladder Events</i>
                </Typography>
              )}
              <Table variant="soft" size="sm" sx={{ textAlign: "left" }}>
                <tbody>
                  {events &&
                    events
                      .filter((event) => event.event_data.ladder === ladder.id)
                      .map((event) => (
                        <tr key={event.id}>
                          <td width={100}>
                            {shortenIfAddress(event.event_data.trader)}
                          </td>
                          <td>{event.event_data.action}</td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    ));
}
