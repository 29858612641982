import { Button, Grid, Sheet, Typography } from "@mui/joy";
import { useEthers } from "@usedapp/core";

export default function ConnectWallet() {
  const { activateBrowserWallet, account } = useEthers();

  return (
    <Sheet
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography level="display2" textAlign="center">
            <i>Connect Wallet</i>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography level="body1" textAlign="center">
            <i>Connect Your Web3 Wallet To View The Ladder</i>
          </Typography>
        </Grid>
        {account && (
          <Grid xs={12}>
            <Typography level="body1" textAlign="center">
              Connected Wallet: {account}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} textAlign="center">
        <Button
            // disabled={!connector.ready || isLoading}
            onClick={activateBrowserWallet}
            size="lg"
          >
            Connect Wallet
          </Button>
        </Grid>
      </Grid>
    </Sheet>
  );
}
