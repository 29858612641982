import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

export default function Community() {
  return (
    <Box
      sx={{
        py: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        level="body2"
        textColor="text.secondary"
      >
        <i>&copy; {new Date().getFullYear()} Congruent Labs</i>
      </Typography>
    </Box>
  );
}
