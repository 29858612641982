import * as React from "react";
import { Sheet, Typography, Box, Alert } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

export default function Parameters() {
  return (
    <Sheet
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography level="display1" textAlign="center">
        <i>STATISTICS</i>
      </Typography>
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", my: 1 }}>
        <Alert
          variant="solid"
          color="danger"
          sx={{ alignItems: "flex-start" }}
          startDecorator={React.cloneElement(<WarningIcon />, {
            sx: { mt: "2px", mx: "4px" },
            fontSize: "xl2",
          })}
        >
          <div>
            <Typography fontWeight="lg" mt={0.25}>
              Development Demo
            </Typography>
            <Typography fontSize="sm" sx={{ opacity: 0.8 }}>
              This is not real data, and buttons may not work.
            </Typography>
          </div>
        </Alert>
      </Box>
    </Sheet>
  );
}
