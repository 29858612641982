import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/joy/styles";
import { HashRouter } from "react-router-dom";
import { DAppProvider, Mainnet } from "@usedapp/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";

const config = {
  autoConnect: true,
  multicallVersion: 2,
  fastMulticallEncoding: true,
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://mainnet.infura.io/v3/6f6fe86a84064070a7d62bdf9ce1d053'
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <StyledEngineProvider injectFirst>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <DAppProvider config={config}>
          <App />
        </DAppProvider>
      </QueryClientProvider>
    </HashRouter>
  </StyledEngineProvider>
);
