import * as React from "react";
import {
  Sheet,
  Typography,
  Grid,
  Card,
  AspectRatio,
  Chip,
} from "@mui/joy";
// import { shortenIfAddress } from "@usedapp/core";

export default function Leaderboard() {
  return (
    <Sheet
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography level="display2" textAlign="center">
            <i>LADDER #1</i>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Card
            variant="outlined"
            row
            sx={{
              gap: 2,
              "&:hover": {
                boxShadow: "md",
                borderColor: "neutral.outlinedHoverBorder",
              },
              bgcolor: "background.body",
            }}
          >
            <div>
              <Typography
                level="h2"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
              >
                TOTAL PROFIT: 4 ETH
              </Typography>
              <Typography
                level="h2"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
              >
                TRADERS: 50
              </Typography>
              <Typography
                level="h2"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
                color="success"
              >
                BIGCAPPERS: 20
              </Typography>
              <Typography
                level="h2"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
                color="danger"
              >
                LIQUIDATORS: 7
              </Typography>
            </div>
          </Card>
        </Grid>
        <Grid xs={12}>
          <Typography level="h2" textAlign="center">
            <i>TOP BIGCAPPERS</i>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Card
            variant="outlined"
            row
            sx={{
              gap: 2,
              "&:hover": {
                boxShadow: "md",
                borderColor: "neutral.outlinedHoverBorder",
              },
              bgcolor: "background.body",
            }}
          >
            <AspectRatio ratio="1" sx={{ width: 90 }} variant="plain">
              <Typography level="display1" color="warning" variant="outlined">
                1
              </Typography>
            </AspectRatio>
            <div>
              <Typography
                level="h2"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
              >
                👑 Profit: 1 ETH
              </Typography>
              <Typography
                fontSize="sm"
                aria-describedby="card-description"
                mb={1}
                noWrap
                sx={{ color: "text.tertiary" }}
              >
                Trader:{" "}
                0xE29F1241E6015A4949cb7E3f07234BA794006181
              </Typography>
              <Chip
                variant="outlined"
                color="warning"
                size="sm"
                sx={{ pointerEvents: "none" }}
              >
                Traded SHIB, QNT, LINK, UNI
              </Chip>
            </div>
          </Card>
        </Grid>
        <Grid xs={12} sm={6}>
          <Card
            variant="outlined"
            row
            sx={{
              // width: 320,
              gap: 2,
              "&:hover": {
                boxShadow: "md",
                borderColor: "neutral.outlinedHoverBorder",
              },
              bgcolor: "background.body",
            }}
          >
            <AspectRatio ratio="1" sx={{ width: 90 }} variant="plain">
              <Typography level="display1" color="success" variant="outlined">
                2
              </Typography>
            </AspectRatio>
            <div>
              <Typography
                level="h3"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
              >
                Profit: 0.9 ETH
              </Typography>
              <Typography
                fontSize="sm"
                aria-describedby="card-description"
                mb={1}
                noWrap
                sx={{ color: "text.tertiary" }}
              >
                Trader:{" "}
                0xE29F1241E6015A4949cb7E3f07234BA794006181
              </Typography>
              <Chip
                variant="outlined"
                color="success"
                size="sm"
                sx={{ pointerEvents: "none" }}
              >
                Traded SHIB, QNT, UNI
              </Chip>
            </div>
          </Card>
        </Grid>
        <Grid xs={12} sm={6}>
          <Card
            variant="outlined"
            row
            sx={{
              // width: 320,
              gap: 2,
              "&:hover": {
                boxShadow: "md",
                borderColor: "neutral.outlinedHoverBorder",
              },
              bgcolor: "background.body",
            }}
          >
            <AspectRatio ratio="1" sx={{ width: 90 }} variant="plain">
              <Typography level="display1" color="success" variant="outlined">
                3
              </Typography>
            </AspectRatio>
            <div>
              <Typography
                level="h3"
                // fontSize="lg"
                id="card-description"
                mb={0.5}
              >
                Profit: 0.8 ETH
              </Typography>
              <Typography
                fontSize="sm"
                aria-describedby="card-description"
                mb={1}
                noWrap
                sx={{ color: "text.tertiary" }}
              >
                Trader:{" "}
                0xE29F1241E6015A4949cb7E3f07234BA79400618
              </Typography>
              <Chip
                variant="outlined"
                color="success"
                size="sm"
                sx={{ pointerEvents: "none" }}
              >
                Traded SHIB, LINK, UNI
              </Chip>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Sheet>
  );
}
