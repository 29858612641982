import * as React from "react";
import Community from "./Home/Community";
import LadderData from "./LadderData";
import StatisticsHeader from "./StatisticsHeader";

export default function Statistics() {
  return (
    <>
      <StatisticsHeader />
      <LadderData />
      <Community />
    </>
  );
}
