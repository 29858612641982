import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export default function About() {
  return (
    <Box
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/logo.png" alt="logo" width="300" height="300" />
      </Box>
      <Typography
        level="display2"
        textColor="text.primary"
        sx={{ letterSpacing: 1 }}
      >
        <i>THIS IS BIGCAP</i>
      </Typography>
      <Typography level="h4" mt={2} mb={2}>
        Centralized services have damaged the image of crypto for years.
        Celsius. FTX. Alameda. Mt Gox. Bitconnect.
      </Typography>
      <Typography level="h4" mt={2} mb={2}>
        Billions taken and lost from users from their behavior, and those
        affected left with nothing.
      </Typography>
      <Typography
        level="h4"
        mt={2}
        mb={2}
        sx={{ textShadow: "0.5px 0.5px 1px red" }}
      >
        This is not the decentralized future that Satoshi envisioned.
      </Typography>
      <Typography level="h4" mt={2} mb={2}>
        BIGCAP is how we take the power back. Collaborative investing. Gamified
        trading. Decentralized ownership. Collateralized treasury protection.
      </Typography>
      <Typography
        level="h4"
        mt={2}
        mb={2}
        sx={{ textShadow: "0.5px 0.5px 1px green" }}
      >
        Read the whitepaper, join the BIGCAP revolution.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          color="success"
          variant="soft"
          size="lg"
          startDecorator={<TextSnippetIcon />}
          component="a"
          href="/whitepaper.pdf"
          target="_blank"
        >
          WHITEPAPER
        </Button>
      </Box>
      <Typography level="h1" textColor="text.primary" sx={{ letterSpacing: 1, mt: 4 }}>
        <i>HOW IT WORKS</i>
      </Typography>
      <Typography level="h4" mt={1} mb={1}>
        1. Buy BIGCAP on Uniswap.
      </Typography>
      <Typography level="h4" mt={1} mb={1}>
        2. When the ladder starts, stake BIGCAP as collateral.
      </Typography>
      <Typography level="h4" mt={1} mb={1}>
        3. Swap treasury ETH from a whitelist of large cap tokens.
      </Typography>
      <Typography level="h4" mt={1} mb={1}>
        4. Before the ladder ends, swap back to ETH to get your BIGCAP back.
      </Typography>
      <Typography level="h4" mt={1} mb={1}>
        5. The best traders win rewards from the ladder.
      </Typography>
    </Box>
  );
}
