import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import HowToVoteIcon from '@mui/icons-material/HowToVote';

export default function Community() {
  return (
    <Box
      sx={{
        borderRadius: "sm",
        p: 2,
        mb: 3,
      }}
    >
      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          level="display2"
          textColor="text.primary"
          sx={{ letterSpacing: 1 }}
        >
          <i>COMMUNITY</i>
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          color="info"
          variant="soft"
          size="lg"
          sx={{ m: 1 }}
          startDecorator={<TelegramIcon />}
          component="a"
          href="https://t.me/bigcaptoken"
        >
          TELEGRAM
        </Button>
        <Button
          color="info"
          variant="soft"
          size="lg"
          sx={{ m: 1 }}
          startDecorator={<TwitterIcon />}
          component="a"
          href="https://twitter.com/BIGCAPProject"
        >
          TWITTER
        </Button>
        <Button
          color="info"
          variant="soft"
          size="lg"
          sx={{ m: 1 }}
          startDecorator={<HowToVoteIcon />}
          component="a"
          href="https://www.tally.xyz/gov/eip155:1:0x442660DDf67dd90f9a75885b2e2312F991b3027B"
        >
          DAO
        </Button>
      </Box>
    </Box>
  );
}
