import * as React from "react";
import { Typography, Alert, Button, Card, Stack, Grid } from "@mui/joy";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { formatUnits } from "@ethersproject/units";
import { useTokenAllowance, shortenIfAddress, useSigner } from "@usedapp/core";
import { Check } from "@mui/icons-material";

import { shouldBeLoading, logLoading, API_ADDRESS } from "../helpers";
import {
  STAKE_CONTRACT,
  TOKEN_CONTRACT,
  getStakeContract,
  useGetSingleValue,
  useTokenApprove,
  useDepositCollateral,
} from "../hooks";

export default function Trader({ account, trader }) {
  const [isLoadingApprove, setLoadingApprove] = React.useState(false);
  const [isLoadingDeposit, setLoadingDeposit] = React.useState(false);
  const [isLoadingRegister, setLoadingRegister] = React.useState(false);
  const [registerError, setRegisterError] = React.useState("");
  const stakeContract = getStakeContract();
  const signer = useSigner();

  const allowance = useTokenAllowance(TOKEN_CONTRACT, account, STAKE_CONTRACT);
  const minimumCollateral = useGetSingleValue(
    "minimumCollateral",
    [],
    STAKE_CONTRACT,
    stakeContract
  );
  const totalCollateral = useGetSingleValue(
    "totalCollateral",
    [],
    STAKE_CONTRACT,
    stakeContract
  );
  const collateralStaked = useGetSingleValue(
    "collateralStaked",
    [account],
    STAKE_CONTRACT,
    stakeContract
  );

  const {
    state: approveState,
    send: approveSend,
    resetState: approveResetState,
  } = useTokenApprove();

  React.useEffect(() => {
    if (approveState) {
      logLoading(approveState, "approve");
      setLoadingApprove(shouldBeLoading(approveState.status));
    }
  }, [approveState, setLoadingApprove]);

  const onClickApprove = (e) => {
    e.preventDefault();
    approveResetState();
    approveSend(STAKE_CONTRACT, minimumCollateral);
  };

  const {
    state: depositState,
    send: depositSend,
    resetState: depositResetState,
  } = useDepositCollateral();

  React.useEffect(() => {
    if (depositState) {
      logLoading(depositState, "deposit");
      setLoadingDeposit(shouldBeLoading(depositState.status));
    }
  }, [depositState, setLoadingDeposit]);

  const onClickDeposit = (e) => {
    e.preventDefault();
    depositResetState();
    depositSend();
  };

  const onClickRegister = async () => {
    try {
      setLoadingRegister(true);
      setRegisterError("");
      const message = `I Approve Registering as a BIGCAP Trader at ${new Date().toISOString()}`;
      const signature = await signer.signMessage(message);
      const data = {
        signedMessage: message,
        signature,
        address: account,
      };
      // console.log(data);
      const response = await axios.post(`${API_ADDRESS}/registerTrader`, data);

      console.log(response);
    } catch (error) {
      console.error(error);
      setRegisterError(error.message);
    } finally {
      setLoadingRegister(false);
    }
  };  
  
  return (
    <Grid container justifyContent="center" rowSpacing={4}>
      <Grid xs={12} md={8} lg={6} xl={4}>
        <Card variant="outlined">
          <Stack spacing={1}>
            <Typography
              level="h2"
              sx={{ textAlign: "center", textTransform: "uppercase" }}
            >
              <i>Trader Registration</i>
            </Typography>
            {minimumCollateral && (
              <Typography level="body1" sx={{ textAlign: "center" }}>
                The current collateral required is{" "}
                {parseFloat(
                  formatUnits(minimumCollateral, 18)
                ).toLocaleString()}{" "}
                BIGCAP
              </Typography>
            )}
            <Alert
              color="warning"
              sx={{ alignItems: "flex-start" }}
              startDecorator={React.cloneElement(<InfoIcon />, {
                sx: { mt: "2px", mx: "2px" },
              })}
            >
              <Typography fontWeight="sm">
                If you stake to trade on ladders, you agree that any amount of
                your staked BIGCAP can be liquidated to cover any losses you
                incur.
              </Typography>
            </Alert>
            <Typography level="body2" sx={{ textAlign: "center" }}>
              <i>Your Collateral Staked with {shortenIfAddress(account)}</i>
            </Typography>
            {collateralStaked && (
              <Typography level="h4" sx={{ textAlign: "center" }}>
                {parseFloat(formatUnits(collateralStaked, 18)).toLocaleString()}{" "}
                BIGCAP
              </Typography>
            )}
            {allowance && minimumCollateral && collateralStaked && (
              <Button
                onClick={onClickApprove}
                loadingPosition="start"
                loading={isLoadingApprove}
                size="sm"
                disabled={
                  isLoadingApprove ||
                  allowance >= minimumCollateral ||
                  collateralStaked >= minimumCollateral
                }
              >
                Step 1: Approve Deposit
              </Button>
            )}
            {approveState && approveState.errorMessage && (
              <Alert color="danger">{approveState.errorMessage}</Alert>
            )}
            {allowance && minimumCollateral && (
              <Button
                onClick={onClickDeposit}
                loadingPosition="start"
                loading={isLoadingDeposit}
                size="sm"
                disabled={isLoadingDeposit || allowance < minimumCollateral || collateralStaked >= minimumCollateral}
              >
                Step 2: Deposit Collateral
              </Button>
            )}
            {depositState && depositState.errorMessage && (
              <Alert color="danger">{depositState.errorMessage}</Alert>
            )}
            <Button
              onClick={onClickRegister}
              loadingPosition="start"
              loading={isLoadingRegister}
              size="sm"
              disabled={
                isLoadingRegister ||
                collateralStaked < minimumCollateral ||
                trader.id !== undefined
              }
            >
              Step 3: Register As Trader
            </Button>
            {registerError && <Alert color="danger">{registerError}</Alert>}
            {trader.id !== undefined && (
              <Alert
                color="success"
                startDecorator={React.cloneElement(<Check />, {
                  sx: { mt: "2px", mx: "2px" },
                })}
              >
                <Typography fontWeight="md">Registered Trader</Typography>
              </Alert>
            )}
            {totalCollateral && (
              <Typography level="body3" sx={{ textAlign: "center" }}>
                Total Collateral Staked:{" "}
                {parseFloat(formatUnits(totalCollateral, 18)).toLocaleString()}{" "}
                BIGCAP
              </Typography>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
