import * as React from "react";
import {
  Button,
  Stack,
  AspectRatio,
  Modal,
  Sheet,
  ModalClose,
  Typography,
  Alert,
  useTheme,
} from "@mui/joy";
import axios from "axios";

import { formatUnits } from "@ethersproject/units";
import { CheckCircle, Report, Warning } from "@mui/icons-material";

import { API_ADDRESS } from "../../helpers";
import moment from "moment/moment";

export default function WhitelistToken({
  isLoading,
  setLoading,
  account,
  signer,
  id,
  item,
  isRegistered,
  trader,
  trades,
}) {
  const theme = useTheme();
  const [openBuyModel, setOpenBuyModal] = React.useState(false);
  const [openSellModel, setOpenSellModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [activeTrade, setActiveTrade] = React.useState(false);
  const [openPosition, setOpenPosition] = React.useState(undefined);

  React.useEffect(() => {
    if (trades) {
      trades.forEach((trade) => {
        if (trade.token_contract === item.contract) {
          if (trade.status === "BUY_VERIFIED") {
            setActiveTrade(true);
            setOpenPosition(trade);
          }
        }
      });
    }
  }, [trades, setActiveTrade, item]);

  const onClickBuy = async (e) => {
    e.preventDefault();
    setOpenBuyModal(true);
  };

  const onClickConfirmBuy = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage("");
      const signedMessage = `I request buying ${
        item.contract
      } at ${new Date().toISOString()}`;
      const signature = await signer.signMessage(signedMessage);
      console.log(signature);
      let data = {};
      if (item.network === "arbitrum") {
        data = {
          addr: account,
          id,
          signedMessage,
          signature,
          token: item.contract,
          tokenName: item.title,
          tokenContract: item.contractArb,
          network: "arbitrum",
          pair: item.pair,
        };
      } else {
        data = {
          addr: account,
          id,
          signedMessage,
          signature,
          token: item.contract,
          tokenName: item.title,
          network: "mainnet",
          tokenContract: item.contract,
          pair: item.pair,
        };
      }
      console.log(data);
      const response = await axios.post(`${API_ADDRESS}/buyToken`, data);
      console.log(response);
      if (response.status === 200) {
        setOpenBuyModal(false);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickSell = async (e) => {
    e.preventDefault();
    setOpenSellModal(true);
  };


  const onClickConfirmSell = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage("");
      const signedMessage = `I request selling ${
        item.contract
      } at ${new Date().toISOString()}`;
      const signature = await signer.signMessage(signedMessage);
      console.log(signature);
      let data = {};
      if (item.network === "arbitrum") {
        data = {
          addr: account,
          id,
          signedMessage,
          signature,
          token: item.contract,
          tokenName: item.title,
          tradeId: openPosition.id,
          tokenContract: item.contractArb,
          quantity: openPosition.quantity,
          network: "arbitrum",
          pair: item.pair,
        };
      } else {
        data = {
          addr: account,
          id,
          signedMessage,
          signature,
          token: item.contract,
          tokenName: item.title,
          tradeId: openPosition.id,
          network: "mainnet",
          tokenContract: item.contract,
          pair: item.pair,
        };
      }
      console.log(data);
      const response = await axios.post(`${API_ADDRESS}/sellToken`, data);
      console.log(response);
      if (response.status === 200) {
        setOpenSellModal(false);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openBuyModel}
        onClose={() => setOpenBuyModal(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Stack spacing={1}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
            >
              {item.title} Buy Request
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
              Confirm you wish to buy{" "}
              <span style={{ fontFamily: "Roboto Mono" }}>
                {item.price &&
                  parseFloat(item.price).toLocaleString()}
              </span>{" "}
              {item.title} for the ladder.
            </Typography>
            <Typography
              id="modal-desc-2"
              textColor="text.tertiary"
              level="body2"
            >
              Before you trade any token we recommend you perform some Technical
              Analysis, such as checking:
            </Typography>
            <ul>
              <li>
                Relative Strength Index (RSI): A high RSI value can indicate
                that a cryptocurrency is overbought, while a low RSI value can
                indicate that a cryptocurrency is oversold.
              </li>

              <li>
                Moving Average Convergence Divergence (MACD): When the MACD line
                crosses above the signal line, it can be a signal to buy, and
                when the MACD line crosses below the signal line, it can be a
                signal to sell.
              </li>

              <li>
                Bollinger Bands: The bands are based on a moving average, and
                they widen when a cryptocurrency is more volatile and narrow
                when it is less volatile. Traders can use this information to
                help identify potential buying or selling opportunities.
              </li>
            </ul>
            <Typography id="modal-desc-3" textColor="text.tertiary">
              If you're unsure what to buy, check with the BIGCAP community!
            </Typography>
            <Alert
              color="warning"
              startDecorator={React.cloneElement(<Warning />, {
                sx: { mt: "2px", mx: "4px" },
                fontSize: "xl2",
              })}
            >
              <Typography>
                Buy transactions may not execute immediately, and may not
                execute for the price shown on this site as it may change
                between requesting a purchase and the purchase being executed.
              </Typography>
            </Alert>
            <Button
              variant="solid"
              color="success"
              loadingPosition="start"
              loading={isLoading}
              aria-label={`Buy ${item.title}`}
              sx={{ fontWeight: 600, textTransform: "uppercase" }}
              onClick={onClickConfirmBuy}
            >
              Buy {item.title}
            </Button>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </Stack>
        </Sheet>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openSellModel}
        onClose={() => setOpenSellModal(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Stack spacing={1}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
            >
              {item.title} Sell Request
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
              Confirm you wish to sell{" "}
              <span style={{ fontFamily: "Roboto Mono" }}>
                {openPosition &&
                  parseFloat(
                    formatUnits(
                      openPosition.quantity.toString() || "0",
                      item.decimals
                    )
                  ).toLocaleString()}
              </span>{" "}
              {item.title} for the ladder.
            </Typography>
            {item.price &&
            openPosition &&
            item.price > openPosition.quantity ? (
              <Alert
                color="success"
                startDecorator={React.cloneElement(<CheckCircle />, {
                  sx: { mt: "2px", mx: "4px" },
                  fontSize: "xl2",
                })}
              >
                Selling now will be at a profit from when you bought, but make
                sure the profit is enough to exceed swap and gas fees for the
                trade (0.5%).
              </Alert>
            ) : (
              <Alert
                color="danger"
                startDecorator={React.cloneElement(<Report />, {
                  sx: { mt: "2px", mx: "4px" },
                  fontSize: "xl2",
                })}
              >
                Selling now will be at a loss from when you bought. If you sell
                at a loss you may have some of your collateral liquidated to
                cover the losses.
              </Alert>
            )}
            <Typography id="modal-desc-3" textColor="text.tertiary">
              If you're unsure what to trade, check with the BIGCAP community!
            </Typography>
            <Alert
              color="warning"
              startDecorator={React.cloneElement(<Warning />, {
                sx: { mt: "2px", mx: "4px" },
                fontSize: "xl2",
              })}
            >
              <Typography>
                Sell transactions may not execute immediately, and may not
                execute for the price shown on this site as it may change
                between requesting a trade and the trade being executed.
              </Typography>
            </Alert>
            <Button
              variant="solid"
              color="success"
              loadingPosition="start"
              loading={isLoading}
              aria-label={`Buy ${item.title}`}
              sx={{ fontWeight: 600, textTransform: "uppercase" }}
              onClick={onClickConfirmSell}
            >
              Sell {item.title}
            </Button>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </Stack>
        </Sheet>
      </Modal>

      <td width={50}>
        <AspectRatio
          ratio="1"
          sx={{ width: 30, borderRadius: 4 }}
          variant="plain"
        >
          <img src={item.logo_url} loading="lazy" alt={item.title} />
        </AspectRatio>
      </td>
      <td width={175} style={{ textAlign: "right", fontFamily: "Roboto Mono" }}>
        {item.price && parseFloat(item.price).toLocaleString()}
        <Typography level="body5">
          Updated {moment(item.updated_at).fromNow()}
        </Typography>
      </td>
      <td width={75} style={{ textAlign: "left" }}>
        {item.title}
      </td>
      <td>
        <Stack direction="row" spacing={0.5}>
          {!activeTrade && trader && trader.status && (
            <Button
              variant="solid"
              size="sm"
              color="info"
              aria-label={`Buy ${item.title}`}
              sx={{ fontWeight: 600, textTransform: "uppercase" }}
              onClick={onClickBuy}
              disabled={
                isLoading ||
                !isRegistered ||
                !openPosition ||
                trader.status === "Position Opened"
              }
            >
              Buy
            </Button>
          )}
          {activeTrade && openPosition && item.price && (
            <Button
              variant="solid"
              size="sm"
              color={
                item.price > openPosition.quantity
                  ? "success"
                  : "danger"
              }
              aria-label={`Sell ${item.title}`}
              sx={{ fontWeight: 600, textTransform: "uppercase" }}
              onClick={onClickSell}
              disabled={!activeTrade}
            >
              Sell
            </Button>
          )}
        </Stack>
      </td>
      {activeTrade && openPosition && item.price ? (
        <td
          width={175}
          style={{ textAlign: "right", fontFamily: "Roboto Mono", color: 
            item.price > openPosition.quantity ? theme.palette.success[500] : theme.palette.danger[500] }}
        >
          <Typography
            level="body4"
          >
            Your Position
          </Typography>
          {parseFloat(
            formatUnits(openPosition.quantity.toString(), item.decimals)
          ).toLocaleString()}
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
}
